import React, { useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DashboardGeneralDateText } from '../DashboardGeneralDateText';
import { DateRangePicker } from '../../../../shared/components/DateRangePicker';
import { sanitizeCustomDate } from '../../../../shared/utils';
import { DateFilter } from '../../../../shared/constants';
import {
  changeDashboardDateFilterStoreAction,
  fetchUsersCountStoreAction,
} from '../../dashboard-actions';
import moment from 'moment/moment';
import { WinnerNotesFullData } from './WinnerNotesAllData';

import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { useAuth } from '../../../auth/auth-hook';
import { fetchUserByMonth } from '../../../user/user-actions';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { WinnerAuthorsRanking } from './WinnerAuthorsRanking';
import { WinnerSectionRanking } from './WinnerSectionRanking';
import { WinnerNotesTotal } from './WinnerNotesTotal';
import { WinnerNotesTotalByFilter } from './WinnerNotesTotalByFilter';

export const DashboardRankingTab: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const [callFetchUser] = useCallAction(fetchUserByMonth, {
    onCompleted: (data) => {
      fetchUsersCountStoreAction(false, data);
    },
    onError: () => {
      fetchUsersCountStoreAction(false, []);
    },
  });

  useEffect(() => {
    if (tenant) {
      callFetchUser({
        tenant,
        from,
        to,
        filter: filter,
      });
    }
  }, [tenant, callFetchUser, from, to, filter]);
  return (
    <Grid container justifyContent="center">
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems="center">
              <Typography>Informe general de registro de usuarios</Typography>

              <Box
                display="flex"
                alignItems="center"
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    justifyContent: 'flex-end',
                  },
                })}>
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {
                    const dateFilter = sanitizeCustomDate(
                      range.key as DateFilter,
                      {
                        startDate: range.startDate,
                        endDate: range.endDate,
                      },
                    );

                    changeDashboardDateFilterStoreAction(
                      moment(range.startDate).startOf('day').utc(true).format(),
                      moment(range.endDate).endOf('day').utc(true).format(),
                      dateFilter,
                    );

                    fetchUsersCountStoreAction(true, []);
                  }}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <WinnerNotesTotal />
          </Grid>

          <Grid item xs={12} md={6}>
            <WinnerNotesTotalByFilter />
          </Grid>

          <Grid item xs={12}>
            <WinnerNotesFullData />
          </Grid>

          <Grid item xs={12}>
            <WinnerAuthorsRanking />
          </Grid>
          <Grid item xs={12}>
            <WinnerSectionRanking />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
