import React, {  useState } from 'react';
import { DashboardCardItem } from '../DashboardCardItem';
import { useAuth } from '../../../auth/auth-hook';
import { useWinnerNotesCountQuery } from '../../../../shared/types/generated';

export const WinnerNotesTotal: React.FC = () => {
  const [total, setTotal] = useState(0);
  const { tenant } = useAuth();

  const { loading } = useWinnerNotesCountQuery({
    variables: {
      data: {
        tenantId: tenant?.id as string,
      },
    },
    onCompleted: (data) => {
      setTotal(data?.winnerNotesCount?.total || 0);
    },
  });

  return (
    <DashboardCardItem
      title="Total notas ganadoras"
      content={total}
      loading={loading}
    />
  );
};
