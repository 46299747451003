import React, { useState } from 'react';
import {
  Typography,
  Link,
  Stack,
  Paper,
  InputBase,
  IconButton,
} from '@mui/material';
import {
  useWinnerNotesAllDataQuery,
  WinnerNotesAllData,
} from '../../../../shared/types/generated';
import { useAuth } from '../../../auth/auth-hook';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { ColumnTitle } from '../../../../shared/components/text/Typographys';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { CardSection } from '../../../../shared/components/card/CardSection';
import { useBreakPoint } from '../../../../shared/hooks/useBreakPoint';
import SearchIcon from '@mui/icons-material/Search';
import { useStore } from '@cobuildlab/react-simple-state';

export const WinnerNotesFullData: React.FC = () => {
  const { isBelow } = useBreakPoint('sm');
  const { tenant } = useAuth();
  const { from, to } = useStore(changeDashboardDateFilterStore);

  const [pagination, setPagination] = useState({
    search: '',
    pageSize: 10,
    page: 1,
  });

  const { data, loading } = useWinnerNotesAllDataQuery({
    variables: {
      data: {
        tenantId: tenant?.id as string,
        from,
        to,
        page: pagination.page,
        pageSize: pagination.pageSize,
        search: pagination.search,
      },
    },
  });
  const items = (data?.winnerNotesAllData?.data as WinnerNotesAllData[]) || [];
  const total = data?.winnerNotesAllData?.total || 0;

  const COLUMNS: TableColumnType<WinnerNotesAllData>[] = [
    {
      columnName: <ColumnTitle textAlign="left">Nota</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <Link href={item?.url || ''} target="_blank">
          <Typography textAlign="left">{item.title}</Typography>
        </Link>
      ),
    },
    {
      columnName: <ColumnTitle>Cantidad</ColumnTitle>,
      columnValue: (item) => item?.count || 0,
    },

    {
      columnName: <ColumnTitle>Autor</ColumnTitle>,
      columnValue: (item) => item?.author || '',
    },
    {
      columnName: <ColumnTitle>Seccion</ColumnTitle>,
      columnValue: (item) => item?.section || '',
    },
  ];

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        width={'100%'}
        alignItems={'center'}
        py={2}
        justifyContent={{ xs: 'space-between' }}>
        <Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
          Notas ganadoras
        </Typography>
        <Paper
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: 300,
            height: '30px',
          }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={isBelow ? 'Buscar' : 'Buscar nota'}
            value={pagination.search}
            onChange={(e) => {
              setPagination({
                ...pagination,
                search: e.target.value,
              });
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter') {
                setPagination({
                  ...pagination,
                  page: 1,
                });
              }
            }}
          />

          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              setPagination({
                ...pagination,
                page: 1,
              });
            }}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Stack>
      <CardSection showCardHeader={false}>
        <TableDefaultSticky
          items={items || []}
          count={total}
          columns={COLUMNS}
          loading={loading}
          messageLoading={'Cargando notas ganadoras...'}
          messageEmpty={'No hay notas ganadoras disponibles'}
          page={pagination.page}
          pageSize={pagination.pageSize}
          handleChangePage={(page) => {
            if (tenant) {
              setPagination({
                ...pagination,
                page: page + 1,
              });
            }
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
          handleChangeRowPerPage={(e) => {
            setPagination({
              ...pagination,
              pageSize: parseInt(e.target.value, 10),
              page: 1,
            });
          }}
        />
      </CardSection>
    </>
  );
};
