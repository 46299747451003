import React, { useState } from 'react';
import {
  Typography,
  Stack,
  Paper,
  InputBase,
  IconButton,
  Box,
} from '@mui/material';
import {
  AuthorRanking,
  useAuthorRankingQuery,
} from '../../../../shared/types/generated';
import { useAuth } from '../../../auth/auth-hook';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { ColumnTitle } from '../../../../shared/components/text/Typographys';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { CardSection } from '../../../../shared/components/card/CardSection';
import { useBreakPoint } from '../../../../shared/hooks/useBreakPoint';
import SearchIcon from '@mui/icons-material/Search';
import { useStore } from '@cobuildlab/react-simple-state';
import { WinnerRankingPie } from './WinnerRankingPie';

export const WinnerAuthorsRanking: React.FC = () => {
  const { isBelow } = useBreakPoint('sm');
  const { tenant } = useAuth();
  const { from, to } = useStore(changeDashboardDateFilterStore);

  const [pagination, setPagination] = useState({
    search: '',
    pageSize: 10,
    page: 1,
  });

  const { data, loading } = useAuthorRankingQuery({
    variables: {
      data: {
        tenantId: tenant?.id as string,
        from,
        to,
        page: pagination.page,
        pageSize: pagination.pageSize,
        search: pagination.search,
      },
    },
  });
  const items = (data?.authorRanking?.data as AuthorRanking[]) || [];
  const total = data?.authorRanking?.total || 0;

  const COLUMNS: TableColumnType<AuthorRanking>[] = [
    {
      columnName: <ColumnTitle textAlign="left">Autor</ColumnTitle>,
      columnValue: null,
      columnComponent: (item) => (
        <Typography textAlign="left">{item?.author || ''}</Typography>
      ),
    },
    {
      columnName: <ColumnTitle>Cantidad</ColumnTitle>,
      columnValue: (item) => item?.count || 0,
    },
  ];

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        width={'100%'}
        alignItems={'center'}
        py={2}
        justifyContent={{ xs: 'space-between' }}>
        <Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
          Ranking de autores
        </Typography>
        <Paper
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: 300,
            height: '30px',
          }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={isBelow ? 'Buscar' : 'Buscar autor'}
            value={pagination.search}
            onChange={(e) => {
              setPagination({
                ...pagination,
                search: e.target.value,
              });
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter') {
                setPagination({
                  ...pagination,
                  page: 1,
                });
              }
            }}
          />

          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              setPagination({
                ...pagination,
                page: 1,
              });
            }}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        width={'100%'}
        alignItems={'center'}
        spacing={3}
        justifyContent={{ xs: 'space-between' }}>
        
        <Box sx={{ width: '100%' }}>
          <CardSection showCardHeader={false}>
            <TableDefaultSticky
              items={items || []}
              count={total}
              columns={COLUMNS}
              loading={loading}
              messageLoading={'Cargando ranking de autores...'}
              messageEmpty={'No hay ranking de autores disponibles'}
              page={pagination.page}
              pageSize={pagination.pageSize}
              handleChangePage={(page) => {
                if (tenant) {
                  setPagination({
                    ...pagination,
                    page: page + 1,
                  });
                }
              }}
              rowsPerPageOptions={[10, 20, 50, 100]}
              handleChangeRowPerPage={(e) => {
                setPagination({
                  ...pagination,
                  pageSize: parseInt(e.target.value, 10),
                  page: 1,
                });
              }}
            />
          </CardSection>
        </Box>

        <Box sx={{ width: '100%' }}>
          <CardSection showCardHeader={false}>
            <WinnerRankingPie
              data={items.map((item) => ({
                label: item.author as string,
                count: item.count as number,
              }))}
              loading={loading}
            />
          </CardSection>
        </Box>
      </Stack>
    </>
  );
};
