import React, {  useState } from 'react';
import { DashboardCardItem } from '../DashboardCardItem';
import { useAuth } from '../../../auth/auth-hook';
import { useWinnerNotesCountQuery } from '../../../../shared/types/generated';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';

export const WinnerNotesTotalByFilter: React.FC = () => {
  const [total, setTotal] = useState(0);
  const { tenant } = useAuth();
  const { from, to } = useStore(changeDashboardDateFilterStore);

  const { loading } = useWinnerNotesCountQuery({
    variables: {
      data: {
        tenantId: tenant?.id as string,
        from,
        to,
      },
    },
    onCompleted: (data) => {
      setTotal(data?.winnerNotesCount?.total || 0);
    },
  });

  return (
    <DashboardCardItem
      title="Total notas ganadoras por filtro"
      content={total}
      loading={loading}
    />
  );
};
